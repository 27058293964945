import { PortableText } from '@portabletext/react';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import SectionComponent from '../components/pageSections/PageSectionComponent';
import CTALink from '../components/CTA/CTALink';
import CTASecondary from '../components/CTA/CTASecondary';
import PageWrap from '../components/PageLayout/PageWrap';
import Serializers from '../serializers/Serializers';
import getLocalizedData from '../utils/getLocalizedData';
import getStringTranslations from '../../gatsby-node-utils/getStringTranslations';
import toKebabCase from '../utils/toKebabCase';

const colorShape = PropTypes.shape({
  color: PropTypes.shape({
    hex: PropTypes.string,
  }),
});

const propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    indexPath: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(PropTypes.any).isRequired,
  }).isRequired,
  data: PropTypes.shape({
    faqTopic: PropTypes.shape({
      introduction: PropTypes.arrayOf(PropTypes.any),
      body: PropTypes.arrayOf(PropTypes.any),
      blocks: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
      callsToAction: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
      category: PropTypes.objectOf(PropTypes.any),
      pageMetadata: PropTypes.objectOf(PropTypes.any),
      title: PropTypes.string.isRequired,
      pageMainTextColor: colorShape,
      pageMainBgColor: colorShape,
      pageMainAccentColor: colorShape,
      breadcrumbLabel: PropTypes.shape({
        en: PropTypes.string,
        no: PropTypes.string,
      }),
      parentPageOverride: PropTypes.any,
    }).isRequired,
    faqSubTopics: PropTypes.arrayOf(PropTypes.any).isRequired,
    page: PropTypes.shape({
      pageMainTextColor: colorShape,
      pageMainBgColor: colorShape,
      pageMainAccentColor: colorShape,
    }),
  }).isRequired,
};

export const query = graphql`
  query FaqTopic($id: String!) {
    theme: sanityFaqTopic(id: { eq: $id }) {
      pageMainTextColor {
        color {
          hex
        }
      }
      pageMainBgColor {
        color {
          hex
        }
      }
      pageMainAccentColor {
        color {
          hex
        }
      }
    }
    faqTopic: sanityFaqTopic(id: { eq: $id }) {
      id
      category {
        title {
          en
          no
        }
      }
      title {
        en
        no
      }
      introduction: _rawIntroduction(resolveReferences: { maxDepth: 5 })
      body: _rawBody(resolveReferences: { maxDepth: 5 })
      blocks: _rawBlocks(resolveReferences: { maxDepth: 10 })
      callsToAction: _rawCallsToAction(resolveReferences: { maxDepth: 5 })
      pageMetadata {
        noindex
        titleTag {
          no
          en
        }
        metaDescription {
          no
          en
        }
        seoImage {
          alt {
            en
            no
          }
          asset {
            id
          }
        }
      }
      SubNavigation: _rawSubNavigation(resolveReferences: { maxDepth: 10 })
      subNavigation {
        _updatedAt
      }
      referencedDocuments: blocks {
        ... on SanityFaqCatalogue {
          categories {
            _updatedAt
          }
        }
      }
      breadcrumbLabel {
        en
        no
      }
      parentPageOverride: _rawParentPageOverride(
        resolveReferences: { maxDepth: 1 }
      )
    }
    faqSubTopics: allSanityFaqSubTopic(
      filter: { category: { id: { eq: $id } } }
    ) {
      nodes {
        id
        title {
          en
          no
        }
        pageMetadata {
          localeSlug {
            en {
              current
            }
            no {
              current
            }
          }
        }
      }
    }
  }
`;

/** Is body.innerText only whitespace
 *
 * @param {*} body
 * @returns {boolean} boolean
 */
const testBodyNotEmpty = (body) =>
  body?.some
    ? body.some(
        (elem) =>
          elem._type !== 'block' ||
          elem.children?.some(
            (innerElement) => innerElement.text.trim() !== '',
          ),
      )
    : false;

/** Is body shorter than 800 characters
 *
 * @param {*} body
 * @returns {boolean} boolean
 */
const shouldHaveShortLayout = (body) =>
  body?.reduce
    ? body.reduce((r, c) => {
        const len =
          c.children?.reduce((res, cur) => {
            const count = res + cur.text.length;
            return count;
          }, 0) ?? 28; // If a component (exp. ButtonGeolocate), count as 28;
        return r + len;
      }, 0) <= 800
    : false;

function FaqTopic({ pageContext, data: { faqTopic, faqSubTopics, theme } }) {
  const { locale, indexPath, localizedPaths } = pageContext;
  const localizedFaqTopic = getLocalizedData(faqTopic, locale);
  const localizedSubFaqTopics = getLocalizedData(faqSubTopics.nodes, locale);
  const { SubNavigation } = faqTopic;
  const localizedSubNavigation = getLocalizedData(SubNavigation, locale);
  const transformedSubFaqTopics = transformSubTopicsData(
    localizedPaths[locale],
    localizedSubFaqTopics,
  );
  const {
    introduction,
    body,
    blocks,
    category,
    callsToAction,
    pageMetadata,
    title,
    breadcrumbLabel,
    parentPageOverride,
  } = localizedFaqTopic;

  const pageBuilder = blocks ?? [];

  /** Quick explination of this mess of a layout system
   *
   * isShortLayout: body.innerText.length <= 800
   *   true:  CTA under the body
   *   false: CTA left of body
   *
   *   body is null or only whitespace:
   *     always display CTA "left of body"
   *
   * body:
   *   null: hidden
   *   "only whitespace": hidden
   *
   */
  const isBodyNotEmpty = testBodyNotEmpty(body);
  const isShortLayout = isBodyNotEmpty ? shouldHaveShortLayout(body) : false;

  const hasCta = callsToAction?.length > 0;

  const stringTranslations = getStringTranslations(locale);

  // Override breadcrumb
  const breadcrumb = {
    label: breadcrumbLabel ?? `${stringTranslations.backTo} ${category?.title}`,
    to: parentPageOverride
      ? parentPageOverride?.pageMetadata?.localeSlug?.current
      : indexPath,
  };

  return (
    <PageWrap
      theme={theme}
      seoData={pageMetadata}
      subNavigation={localizedSubNavigation}
    >
      <section className="py-5 mb-5 section bg-off-white">
        <div className="flex flex-col items-center section-wrap px-7 xl:px-0 xl:flex-row xl:items-start">
          <div
            className={classNames(
              { hidden: !category, 'flex xl:block': category },
              'flex-col w-full mt-7 lg:mt-16 mb-7 section-column xl:w-68 xl:mr-15 xl:shrink-0 xl:mt-5',
            )}
          >
            <CTALink
              as="button"
              margin="0"
              arrowRotation={180}
              to={breadcrumb.to}
            >
              {breadcrumb.label}
            </CTALink>
          </div>

          <div className="prose section-content prose-ul:list-none">
            <header className="prose xl:mb-15 prose-p:text-lg prose-headings:my-3 prose-a:text-inherit prose-ul:list-none">
              <h1>{title}</h1>

              {introduction && (
                <div className="prose prose-a:text-inherit prose-ul:list-none">
                  <PortableText value={introduction} components={Serializers} />
                </div>
              )}
            </header>
            {transformedSubFaqTopics && (
              <header className="my-6 lg:my-12">
                {transformedSubFaqTopics.map((subTopic) => (
                  <CTALink
                    key={subTopic.id}
                    margin="0.5rem 0.5rem 0"
                    to={subTopic.path}
                    className="mt-2 mr-2"
                  >
                    {subTopic.title}
                  </CTALink>
                ))}
              </header>
            )}
          </div>
        </div>
      </section>
      {(isBodyNotEmpty || hasCta) && (
        <section className="py-5 section">
          <div className="flex flex-col-reverse items-center section-wrap px-7 xl:px-0 xl:flex-row xl:items-start">
            {callsToAction && !isShortLayout && (
              <div className="flex flex-col w-full section-column xl:block xl:w-68 xl:shrink-0 xl:mr-15 xl:mt-0 mt-15">
                {callsToAction.map((item) => (
                  <CTASecondary key={item.id} data={item} />
                ))}
              </div>
            )}
            {isBodyNotEmpty && (
              <div className="mb-8 prose section-content prose-ul:list-none">
                <div className="prose prose-a:text-inherit">
                  <PortableText value={body} components={Serializers} />
                </div>

                {callsToAction && isShortLayout && (
                  <div className="flex flex-col w-full section-column xl:block xl:w-68 xl:shrink-0 xl:mr-15 xl:mt-0 mt-15">
                    {callsToAction?.map((item) => (
                      <div className="mt-7">
                        <CTASecondary data={item} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </section>
      )}
      {pageBuilder.map((section) => (
        <SectionComponent
          key={section._key}
          componentType={section._type}
          locale={locale}
          {...section}
        />
      ))}
    </PageWrap>
  );
}

FaqTopic.propTypes = propTypes;

const transformSubTopicsData = (path, subTopics) => {
  // path = /vorter or /en/warts
  // Just want the "topic"
  const basePath = path.split('/').pop();
  return (
    subTopics
      // eslint-disable-next-line no-underscore-dangle
      .map((subTopic) => {
        const subTopicSlug =
          subTopic.pageMetadata && subTopic.pageMetadata.localeSlug
            ? subTopic.pageMetadata.localeSlug.current
            : toKebabCase(subTopic.title);
        return {
          ...subTopic,
          path: `${basePath}/${subTopicSlug}`,
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title))
  );
};

export default FaqTopic;
